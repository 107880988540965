import { Link } from 'react-router-dom';
import useTranslate from '../../translation/i18n';
import useChangeTitle from '../../api/hooks/useChangeTitle';
import { Dispatch, SetStateAction } from 'react';

type Props = {
    showNewPassError: boolean;
    setNewPassword: Dispatch<SetStateAction<string>>;
    showConfirmPassError: boolean;
    setConfirmPassword: Dispatch<SetStateAction<string>>;
    disableButton: boolean;
    handleKeyDown: (e: React.KeyboardEvent) => void;
    handleSubmit: () => void;
};

export function ChangePassword({
    showNewPassError,
    setNewPassword,
    showConfirmPassError,
    setConfirmPassword,
    disableButton,
    handleKeyDown,
    handleSubmit,
}: Props) {
    const { t } = useTranslate();

    const title = t('change_password.title.initial');
    useChangeTitle(title);

    return (
        <div id="create-new-password" className="create-new-password">
            <div
                id="create-new-password-overlay"
                className="create-new-password-overlay"
            >
                <div className="row title">
                    <h1>{title}</h1>
                </div>
                <div className="row description">
                    <p>{t('change_password.description.initial')}</p>
                </div>
                <div className="row description-requirements">
                    <ul>
                        <li>{t('change_password.requirements.8_char')}</li>
                        <li>{t('change_password.requirements.lower_upper')}</li>
                        <li>{t('change_password.requirements.num_special')}</li>
                        <li>{t('change_password.requirements.no_username')}</li>
                        <li>
                            {t('change_password.requirements.no_last_pass')}
                        </li>
                    </ul>
                </div>
                <div className="pure-control-group">
                    <div className="input-wrapper">
                        <input
                            id="new-password"
                            name="new-password"
                            type="password"
                            title={t('login.password.input.error')}
                            className={
                                showNewPassError ? 'error_input' : undefined
                            }
                            onChange={(e) => setNewPassword(e.target.value)}
                            data-testid="new-password-input"
                            required
                        />
                        <label
                            htmlFor="new-password"
                            className={
                                showNewPassError ? 'label-error' : undefined
                            }
                        >
                            {t(
                                'change_password.input.password.new.placeholder',
                            )}
                        </label>
                    </div>
                </div>
                <div className="new-password-invalid-layout">
                    {showNewPassError && (
                        <span className="error" id="invalid-password-error">
                            {t('change_password.input.password.new.error')}
                        </span>
                    )}
                </div>

                <div className="pure-control-group">
                    <div className="input-wrapper">
                        <input
                            id="confirm-new-password"
                            name="confirm-new-password"
                            type="password"
                            title={t('login.password.input.error')}
                            className={
                                showConfirmPassError ? 'error_input' : undefined
                            }
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            data-testid="confirm-password-input"
                            required
                        />
                        <label
                            htmlFor="confirm-new-password"
                            className={
                                showConfirmPassError ? 'label-error' : undefined
                            }
                        >
                            {t(
                                'change_password.input.password.confirmation.placeholder',
                            )}
                        </label>
                    </div>
                </div>
                <div className="confirm-new-password-invalid-layout">
                    {showConfirmPassError && (
                        <span className="error" id="passwords-different-email">
                            {t(
                                'change_password.input.password.confirmation.error',
                            )}
                        </span>
                    )}
                </div>

                <div className="sticky_bottom">
                    <div className="pure-controls">
                        <button
                            disabled={disableButton}
                            id="change-password"
                            onClick={handleSubmit}
                            data-testid="change-password-button"
                        >
                            {t('change_password.button.reset')}
                        </button>
                    </div>
                    <div className="pure-controls">
                        <Link id="back-to-sign-in" to="renderer">
                            {t('common.back_sign_in')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
